<template>
    <div class="component-reco-cell">
        <div
            v-if="item.recommend != undefined && item.recommend.status"
            class="link-list-outer"
        >
            <div style="color: #909399; display: block">{{
                item.recommend.description
            }}</div>
            <div>
                <div
                    v-for="(cell, index) in item.recommend.list"
                    style="padding: 4px"
                    :key="cell.id + '_' + index"
                    class="link-cell cursor"
                    >{{ cell.text }}</div
                >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["item"],
    data() {
        return {};
    },
};
</script>
<style lang="less" scope>
.component-reco-cell {
    display: block;
    .link-list-outer {
        font-size: 14px !important;
        .link-cell {
            line-height: 16px !important;
            display: block !important;
            color: #366AFF !important;
            cursor: text !important;
        }
    }
}
</style>
